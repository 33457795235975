// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Lato", sans-serif;

$borderRadius: 5px;
$fontSizeBig: 45px;
$fontSizeNormal: 20px;

$topNavHeight: 120px;

$leftNavHeight: calc(100vh - #{$topNavHeight});
$leftNavWidth: 400px;

$mainColor: #3E509A;
$mainColorDark: #242C4B;
$mainColorDarkest: #1C2039;
$grey: #aaa;
$white: #fff;
$textColor: #333;

$successColor: #108A51;
$warningColor: #FFCF0E;
$infoColor: #90CAF9;
$dangerColor: #AE1919;

$breakPointBig: 1200px;


//Shadows
$defaultShadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$smallShadow: 0 2px 5px 0 rgba(0, 0, 0, 0.298039);
$smallShadowRaised: 0 4px 7px 0 rgba(0, 0, 0, 0.298039);
$textShadow: 1px 1px 1px rgba(0, 0, 0, 0.5);