.topNav{
  font-size: $fontSizeBig * 0.75;
  height: $topNavHeight * 0.75;
  line-height: calc(#{$topNavHeight * 0.75} - 20px);
  width: 100%;
}

.sideNav{
  top: $topNavHeight * 0.75;
  display: none;
}

.content{
  left: 0;
  width: 100%;
  top: $topNavHeight * 0.75;
  height: calc(100% - #{$topNavHeight} * 0.75)
}