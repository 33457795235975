.highcharts-container{
  border-radius: $borderRadius * 2;
}

.highcharts-color-0 {
  fill: $mainColor;
  stroke: $mainColor;
}

.highcharts-color-1 {
  fill: $successColor;
  stroke: $successColor;
}

.highcharts-color-2 {
  fill: $dangerColor;
  stroke: $dangerColor;
}

.highcharts-color-3 {
  fill: $warningColor;
  stroke: $warningColor;
}

.highcharts-color-4 {
  fill: $mainColorDarkest;
  stroke: $mainColorDarkest;
}

.highcharts-color-5 {
  fill: $mainColorDark;
  stroke: $mainColorDark;
}

.highcharts-color-6 {
  fill: $infoColor;
  stroke: $infoColor;
}