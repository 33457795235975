.panel.panel-default {
  border-radius: $borderRadius;
  background-color: $white;
  box-shadow: $defaultShadow;
  color: $mainColor;
  border: 0;
  .panel-heading {
    background-color: $white;
    color: $mainColor;
    border-radius: inherit;
    font-size: 35px;
    font-weight: 200;
    border-bottom: 0;
  }
}

.form-control {
  border-bottom: 2px solid $grey;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-radius: 0;
  box-shadow: none;
  &:focus {
    border-color: $mainColor;
    box-shadow: none;
  }
}

.form-group {
  margin-bottom: 30px;
}

.btn {
  box-shadow: $smallShadow;
  border-radius: $borderRadius;
  &:hover {
    box-shadow: $smallShadowRaised;
  }
  &.btn-success {
    background-color: $successColor;
    color: $white;
    border-color: $successColor;
    &:hover {
      color: $successColor;
      background-color: $white;
    }
  }

  &.btn-warning {
    background-color: $warningColor;
    color: #333;
    border-color: $warningColor;
    &:hover {
      color: $warningColor;
      background-color: $white;
    }
  }

  &.btn-danger {
    background-color: $dangerColor;
    color: $white;
    border-color: $dangerColor;
    &:hover {
      color: $dangerColor;
      background-color: $white;
    }
  }
}

.alert {
  border: 0;
  border-radius: $borderRadius;
  color: $white;
  &.alert-danger {
    background-color: $dangerColor;
  }
  &.alert-success {
    background-color: $successColor;
  }
  &.alert-warning {
    background-color: $warningColor;
    color: #333;
  }
}

table.table-striped tr:nth-child(odd) td {
  background-color: lighten($mainColorDarkest, 5%)
}

table.table > thead > tr > th {
  border-bottom-color: lighten($mainColorDarkest, 10%)
}

table.table > tbody > tr > td, table.table > thead > tr > th {
  border-top: 0;
}

.modal {
  color: $textColor;
}

