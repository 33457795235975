html {
  position: relative;
}

body {
  background-color: $mainColorDarkest;
  font-family: $font-family-sans-serif;
  color: $mainColor;
  min-height: 100vh;
  overflow: hidden;
}

.topNav {
  width: 100vw;
  height: $topNavHeight;
  background-color: $white;
  box-shadow: $defaultShadow;
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  font-size: $fontSizeBig;
  font-weight: 200;
  line-height: calc(#{$topNavHeight} - 20px);
  padding-top: 10px;

  .brandName {
    padding-left: 40px;
    width: 40%;
    a:hover {
      text-decoration: none;
    }
  }

  .login {
    flex-grow: 1;
    text-align: right;
    padding-right: 40px;
    font-size: 35px;
  }
}

.sideNav {
  width: $leftNavWidth;
  height: $leftNavHeight;
  position: absolute;
  left: 0;
  top: $topNavHeight;
  background-color: $mainColorDark;
  box-shadow: $defaultShadow;
  color: $white;
  padding-top: 40px;
  ul {
    a {
      color: $white;
      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }
    padding: 0;
    li {
      list-style-type: none;
      padding-left: 40px;
      font-size: 35px;
      font-weight: 200;
      i.fas{
        width: 45px;
        text-align: center;
      }
      &:hover {
        background-color: lighten($mainColorDark, 5%);
      }
    }
  }

  .donate {
    $donateSize: $leftNavWidth * 0.4;
    background: $mainColorDarkest;
    box-shadow: $defaultShadow;
    border-radius: $borderRadius;
    width: $donateSize;
    height: $donateSize;
    margin: auto;
    left: calc(#{$leftNavWidth} / 2 - #{$donateSize} / 2);

  }

  .imprint {
    position: absolute;
    width: 100%;
    bottom: 20px;
    padding: 0 20px;
    color: $grey;
    a {
      color: currentColor;
    }
  }
}


.content {
  position: absolute;
  left: $leftNavWidth;
  top: $topNavHeight;
  width: calc(100vw - #{$leftNavWidth});
  height: calc(100vh - #{$topNavHeight});
  padding: 10px 0 20px 10px;
  color: $white;
  overflow-y: auto;
  h1 {
    font-weight: 200;
  }

}
.content:after {
  content: " ";
  height: 20px;
  display: block;
}

